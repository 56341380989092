<template>
  <div class="dropWCheck">
    <div class="visible_part" @click="toggleOpen">
      <div class="label_title">
        <span>{{ label }}</span>
      </div>
      <div class="nb_selected" :class="{show: count > 0}">
       <span>{{ count }}</span>
      </div>
      <div class="arrow_down">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 407.437 407.437"
          style="enable-background:new 0 0 407.437 407.437;"
          xml:space="preserve"
        >
          <polygon
            points="386.258,91.567 203.718,273.512 21.179,91.567
            0,112.815 203.718,315.87 407.437,112.815"
          />
        </svg>
      </div>
    </div>
    <div class="invisible_part">
      <div
        class="elem_check"
        v-for="elemF in fields"
        :key="elemF.id"
        :class="{selected: elemF.selected }"
        @click="(e) => toggleSelected(e, elemF.id)"
      >
        <div class="checkbox"></div>
        <div class="label">{{ elemF.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropWCheck',
  props: {
    label: String,
    fields: Array,
  },
  computed: {
    count() {
      return this.fields.filter((field) => field.selected).length;
    },
  },
  methods: {
    toggleOpen(e) {
      e.preventDefault();
      const that = e.target;
      const allOpen = document.querySelectorAll('.open');
      allOpen.forEach((elem) => {
        if (elem !== that.parentNode) {
          elem.classList.remove('open');
        }
      });
      if (that.parentNode.classList.contains('open')) {
        that.parentNode.classList.remove('open');
      } else {
        that.parentNode.classList.add('open');
      }
    },
    toggleSelected(e, index) {
      this.fields[index].selected = !this.fields[index].selected;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .dropWCheck{
        width:100%;
        height: 100%;
        background-color: #ffffff;
        position: relative;
        display: flex;
        cursor: pointer;
        z-index: 1;

        .visible_part{
          font-size: $form_typo_size;
          background-color: inherit;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          padding: 15px 30px;
          *{
            pointer-events: none;
            user-select: none;
          }
          .nb_selected{
            display: none;
            background-color: $border_gray;
            height: 16px;
            width: 16px;
            position:relative;
            border-radius: 50%;
            margin-left: 10px;
            transition: opacity .2s;
            span{
              position: absolute;
              top: 50%;
              left: 50%;
              line-height: 1.1;
              transform: translateX(-50%) translateY(-50%);
              font-size: 10px;
              color: #ffffff;
            }
            &.show{
              display: block;
            }
          }
          .arrow_down{
            width: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            transition: transform 0.2s;
            svg{
              width: 100%;
            }
          }
        }

        .invisible_part{
          display: none;
          position:absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          width: 150px;
          max-height: 300px;
          background-color: $fond_gray;
          padding: 10px;
          border: 1px solid $fond_cta;
          .elem_check{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            .checkbox{
              position: relative;
              width: 12px;
              height: 12px;
              background-color: #ffffff;
              border: 1px solid $fond_cta;
              margin: 0 10px 0 0;
              pointer-events: none;
            }
            .label{
              pointer-events: none;
            }

            &.selected{
              .checkbox{
                &:after{
                  content:'';
                  width:10px;
                  height:10px;
                  background-color: $fond_cta;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                }
              }
            }
          }
        }

        &.open{
          z-index: 2;
          box-shadow: rgba(153, 153, 153, 1) 0px 0px 0px 3px;
          .invisible_part{
            display: block;
          }
          .arrow_down{
            transform: rotate(180deg);
          }
        }
    }
</style>

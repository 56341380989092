<template>
  <div class="jobpage" @click="untoggleopen">
    <div class="container_filter_results container_full">
      <div class="container">
        <h2>Find the job that's right for you.</h2>
        <div class="filter_result">
          <div class="cont_field_filters">
            <input type="text" id="search_job" placeholder="Search by keyword" v-model="searchText">
          </div>
          <div class="cont_field_filters">
            <DropSelect
              v-bind:label="'Location'"
              v-bind:fields="this.location"
            ></DropSelect>
            <DropSelect
              v-bind:label="'Remote'"
              v-bind:fields="this.remote"
            ></DropSelect>
          </div>
          <div class="cont_field_filters cont_dropwcheck">
            <DropWCheck
              v-bind:label="'Sector'"
              v-bind:fields="this.sector"
            ></DropWCheck>
          </div>
          <div class="cont_field_filters cont_dropwcheck">
            <DropWCheck
              v-bind:label="'Contract'"
              v-bind:fields="this.contract"
            ></DropWCheck>
          </div>
        </div>
        <div class="btn-green radius" @click="searchJobs" >Search</div>
      </div>
    </div>
    <div class="container">
      <div class="inner_result">
        <JobElemList
          v-for="jobelem in this.entries"
          :key="jobelem.id"
          v-bind:elem="jobelem"
        ></JobElemList>
      </div>
    </div>
    <div class="btn-green radius" @click="getNextPage" v-if="this.nextPageAvailable"> Next</div>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DropWCheck from '@/components/UniversalComponents/Offer/JobFilters/DropWCheck.vue';
import DropSelect from '@/components/UniversalComponents/Offer/JobFilters/DropSelect.vue';
import JobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';
import QueryOptions from '@/components/UniversalComponents/Offer/JobFilters/QueryOptions.json';

export default {
  name: 'JobsPage',
  components: {
    DropWCheck,
    DropSelect,
    JobElemList,
  },
  computed: {
    ...mapState('search/offer', ['entries', 'endOfSearch']),
    ...mapGetters({
      nextPageAvailable: 'search/offer/nextPageAvailable',
    }),
  },
  data() {
    const mapOptions = (option) => QueryOptions[option].map((entry, index) => {
      const newEntry = {};
      if (typeof entry !== 'object') {
        newEntry.data = entry;
        newEntry.name = entry;
      } else {
        newEntry.data = entry.data;
        newEntry.name = entry.name;
      }
      newEntry.selected = false;
      newEntry.id = index;
      return newEntry;
    });
    return {
      location: mapOptions('location'),
      sector: mapOptions('sector'),
      remote: mapOptions('remote'),
      contract: mapOptions('contract'),
      searchText: '',
    };
  },
  methods: {
    untoggleopen(e) {
      const that = e.target;
      const allOpen = document.querySelectorAll('.open');
      if ((!that.parentNode.classList.contains('dropWCheck') && !that.parentNode.classList.contains('dropSelect')) && !that.parentNode.classList.contains('invisible_part')) {
        allOpen.forEach((elem) => {
          elem.classList.remove('open');
        });
      }
    },
    searchJobs() {
      const parseField = (fields) => {
        const selectedFields = fields.filter((field) => field.selected);
        if (selectedFields.length > 0) {
          return selectedFields.map((field) => field.data);
        }
        return undefined;
      };
      const searchQuery = {
        location: parseField(this.location),
        sector: parseField(this.sector),
        remote: parseField(this.remote),
        contract: parseField(this.contract),
        text: this.searchText !== '' ? this.searchText : false,
      };
      this.$store.dispatch('search/offer/getEntriesInitial', searchQuery);
    },
    getNextPage() {
      this.$store.dispatch('search/jobSearch/getNextEntries');
    },
  },
  mounted() {
    this.$store.dispatch('search/offer/getEntriesInitial', {});
  },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";
    .jobpage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        min-height: 200px;

        .container_filter_results{
          background-color: $fond_gray;
          .filter_result{
            width: 100%;
            padding: 30px 0 0 0;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .cont_field_filters{
              position: relative;
              font-size: 16px;
              display:flex;
              flex-direction: row;
              align-items: center;
              input, select{
                position: relative;
                z-index: 1;
                height: 100%;
                font-size: inherit;
                padding: 15px 30px;
                background-color: #ffffff;
                border: none;
                &:focus{
                  z-index: 2;
                  outline: none;
                  box-shadow: rgba(153, 153, 153, 1) 0px 0px 0px 3px;
                }
              }
              #search_job{
                border-top: 1px solid #ffffff;
              }
              &:before{
                background-color: $border_gray;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 1px;
                z-index: 10;
                height: 100%;
                content: " ";
              }
              &:first-child{
                &:before{
                  display: none;
                }
              }
            }

            .cont_dropwcheck{
              display: flex;
              flex-direction: row;
              position: relative;
            }
          }
        }

        .inner_result{
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
        }

    }
</style>
